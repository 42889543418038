body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

td a {
  color: #1e633c;
}
td a:hover {
  color: #1e633c;
}

.ant-table-tbody tr td {
  padding: 6px 16px;
}

.ant-btn-primary {
  border-color: #35b06a;
  background: #35b06a;
  color: white;
}
.ant-btn-primary:hover {
  border-color: #35b06a !important;
  background: #35b06a;
  color: white !important;
}
.ant-btn-primary:focus {
  border-color: #35b06a !important;
  background: #35b06a;
  color: white !important;
}
.ant-btn:not(.ant-btn-dangerous):hover {
  color: #35b06a;
  border-color: #35b06a;
}
.ant-switch-checked {
  background: #35b06a;
}
.ant-radio-inner::after {
  background-color: #35b06a;
  border-bottom-color: #35b06a;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #35b06a;
  border-color: #35b06a;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #40b0a9;
  border-color: #40b0a9;
}

.ant-pagination-item:hover,
.ant-pagination-item-active:hover,
.ant-pagination-item-active:focus,
.ant-pagination-item-active {
  border-color: #35b06a;
}

.ant-pagination-item,
.ant-pagination-item:hover a,
.ant-pagination-item a,
.ant-pagination-item-active:hover,
.ant-pagination-item-active:hover a,
.ant-pagination-item-active a {
  color: #35b06a;
}

.ant-pagination-item-link,
.ant-pagination-item-link {
  color: #35b06a !important;
}

.ant-pagination-item-link:hover,
.ant-pagination-prev:hover,
.ant-pagination-next:hover {
  border-color: #35b06a !important;
}
